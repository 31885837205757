@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-2xl font-bold py-4;
  }

  h2 {
    @apply text-xl font-bold py-4;
  }

  button {
    @apply rounded px-4 py-1;
  }
}
